import {
  TableCell,
  TableRow,
  Typography,
  Badge,
  Grid,
  CircularProgress,
} from "@mui/material";
import { IVariation } from "../types/Types";
import StatsigContainer from "./StatsigContainer";
import { useTheme } from "@mui/material/styles";

export const Row = (props: IVariation) => {
  const {
    variationIsControl,
    variationId,
    significanse,
    conf_max,
    conf_min,
    pct_change,
    mean_value,
    metric,
    conversions_on_the_metric,
    stats,
    category,
    isSegmented,
    running_mde,
  } = props;

  const theme = useTheme();

  const formatValue = () => {
    switch (metric.type) {
      case "prop":
        return (mean_value * 100).toFixed(2) + "%";
      case "means":
        const suffix =
          metric.currency && metric.currency !== "false" ? " SEK" : "";
        return (
          Intl.NumberFormat().format(Number(mean_value.toFixed(2))) + suffix
        );
      case "count":
        return Intl.NumberFormat().format(Number(mean_value.toFixed(2)));
      default:
        break;
    }
  };
  const varCol = props.getVariationColor(variationId);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Grid container justifyContent="flex-start" sx={{ flexWrap: "nowrap" }}>
          <Grid item xs="auto">
            <Typography noWrap={true} variant="subtitle2">
              {props.getVariationName(Number(variationId))}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Badge
              sx={{ "& .MuiBadge-badge": { backgroundColor: varCol } }}
              variant="dot"
            >
              <span style={{ marginLeft: 10 }}>&nbsp;</span>
            </Badge>
          </Grid>
        </Grid>
        <Typography fontSize={11}>
          Visitors:{" "}
          {props.getVariationVisitorCount(variationId) === 0 ? (
            <CircularProgress size={8} color="inherit" />
          ) : (
            props.getVariationVisitorCount(variationId)
          )}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          color:
            category === "monitoring"
              ? theme.palette.text.disabled
              : stats && stats.statSig
              ? stats.isWinner
                ? theme.palette.success.main
                : theme.palette.error.main
              : theme.palette.text.secondary,
        }}
        align="right"
      >
        {pct_change !== null
          ? `${
              pct_change > 0
                ? `+${pct_change.toFixed(2)}%`
                : `${pct_change.toFixed(2)}%`
            }`
          : "---"}
      </TableCell>

      <TableCell align="right">{formatValue()}</TableCell>
      <TableCell align="right">
        {Intl.NumberFormat().format(conversions_on_the_metric)}
      </TableCell>

      <TableCell
        align="right"
        sx={{
          color:
            category === "monitoring" ? theme.palette.text.disabled : "inherit",
        }}
      >
        {!variationIsControl && !(conf_min === null && conf_max === null) ? (
          <StatsigContainer
            stats={stats}
            conf_min={conf_min}
            conf_max={conf_max}
            containerWidth={80}
          />
        ) : (
          <span>---</span>
        )}
      </TableCell>
      <TableCell align="right">
        {!isSegmented && running_mde !== null
          ? running_mde.toFixed(2) + "%"
          : "---"}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          color:
            category === "monitoring"
              ? theme.palette.text.secondary
              : stats && stats.statSig
              ? stats.isWinner
                ? theme.palette.success.main
                : theme.palette.error.main
              : theme.palette.text.secondary,
        }}
      >
        {!isSegmented && significanse !== null
          ? Math.min(99.99, 100 - significanse * 100).toFixed(2) + "%"
          : "---"}
      </TableCell>
    </TableRow>
  );
};
